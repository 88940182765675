.Projects {
  background-color: #697565; /* Light greenish background */
  color: #ECDFCC; /* Light text color */
  padding: 3rem 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.Projects h2 {
  margin-bottom: 20px;
}
