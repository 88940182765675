.LandingPage {
    background: url("../assets/background.webp") no-repeat;
    background-size: cover;
    background-position: center;
    color: #ECDFCC; /* Light text color */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px); /* Full viewport height minus header */
    text-align: center;
    padding: 3rem 20px; /* Add padding for better spacing */
    position: relative; /* For absolute positioning of the image */
  }
  .landing-page-image {
    border-radius: 250px;
  }
  
  .landing-image {
    width: 150px; /* Adjust size as needed */
    height: 150px; /* Maintain aspect ratio */
    border-radius: 50%; /* Circular image */
    border: 5px solid #ECDFCC; /* Border to make image stand out */
    margin-bottom: 20px; /* Space between image and text */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add subtle shadow for depth */
  }
  
  .LandingPage h1 {
    font-family: 'Source Code Pro', monospace; /* Ensure the font is consistent */
    font-weight: 700; /* Bold weight for emphasis */
  }
  
  .LandingPage p {
    font-family: 'Source Code Pro', monospace; /* Consistent font */
    font-weight: 400; /* Normal weight for body text */
  }
  .LandingPage p {
    max-width: 650px; /* Max width for large screens */
    margin: 0 auto; /* Center align text */
    line-height: 1.6; /* Improve readability */
    padding: 0 10px; /* Add some padding for small screens */
  }
  
  @media (max-width: 768px) {
    .LandingPage p {
      max-width: 90%; /* Adjust to 90% of the screen width on small screens */
    }
  }
  
  @media (max-width: 480px) {
    .LandingPage p {
      max-width: 100%; /* Full width on very small screens */
    }
  }
  
  

  