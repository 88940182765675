.slider {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 900px;
    margin: 0 auto;
    overflow: hidden;
  }
  
  .slider-wrapper {
    display: flex;
    transition: transform 0.5s ease-in-out; /* Smooth transition */
    width: 100%;
  }
  
  .slider-card {
    display: flex;
    align-items: center; /* Center content vertically */
    min-width: 100%;
    flex-shrink: 0;
    box-sizing: border-box;
    justify-content: space-between;
    width: 65%;
    height: 500px;
    background: #ECDFCC;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    position: relative;
    padding: 20px;
  }
  
  .slider-content {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    flex: 1;
    color: black;
    height: 100%; /* Ensures full height */
  }
  
  .slider-title {
    font-size: 1.5rem;
    margin: 0;
    color: black;
  }
  
  .slider-description {
    margin: 10px 20px;
    font-size: 1rem; /* Adjust as needed */
  }
  
  .slider-tech {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; 
    position: absolute;
    bottom: 20px;
    font-size: 0.9rem;
    color: #1E201E;
  }
  
  .tech-icon {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .slider-image {
    width: 360px; 
    height: 405px; 
    object-fit: cover; 
    border-radius: 8px; 
    margin: 10px 30px;
  }
  
  /* @media (max-width: 768px) {
    .slider-card {
      width: 85%;
      height: 300px;
    }
    
    .slider-image {
      width: 150px;
      height: 150px;
    }
  } */
  @media (max-width: 768px) {
    .slider-image {
      display: none;
    }
  
    .slider-description {
      font-size: 0.9rem; /* Smaller text for readability */
      padding-left: 10px;
      padding-right: 10px;
    }
  
    .slider-tech {
      font-size: 0.8rem; /* Adjust font size for tech icons */
    }
  
    .slider-card {
      padding: 10px; /* Reduce padding */
    }
  }
  
  .slider-button {
    background: none;
    border: none;
    color: #333; 
    font-size: 2rem;
    padding: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
    opacity: 0.5;
    transition: opacity 0.2s ease;
  }
  
  .slider-button:hover {
    opacity: 1;
  }
  
  .slider-button.left {
    left: 10px;
  }
  
  .slider-button.right {
    right: 10px;
  }
  