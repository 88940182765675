@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;700&display=swap');

/* General Styles */
body {
  margin: 0;
  font-family: 'Source Code Pro', monospace;
  color: #333;
  background-color: #ECDFCC; /* Light background color for the entire page */
}

/* App Container */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Header */
.App-header {
  background-color: #1E201E; /* Dark background for header */
  padding: 20px;
  border-bottom: 2px solid #3C3D37; /* Subtle border for separation */
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between the header content and nav links */
}

/* Header Content */
.header-content {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */
}

/* Header Icon */
.header-icon {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  color: #ECDFCC; /* Icon color */
}

/* Header Text */
.header-text {
  color: #ECDFCC; /* Light text color */
  font-size: 1.5rem; /* Adjust size as needed */
  font-weight: bold;
}

/* Navigation Links */
.nav-links {
  display: flex;
  flex-direction: row; /* Horizontal layout for larger screens */
  gap: 20px;
}

.nav-links a {
  font-size: 1.5rem;
  color: #ECDFCC;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
  transition: all 0.3s ease;
  font-weight: bold;
  cursor: pointer;
}

.nav-links a:hover {
  background-color: #3C3D37;
  color: #ECDFCC;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Sections */
.section {
  padding: 82px 20px;
  text-align: center;
}

/* Section Colors */
.LandingPage {
  background-color: #1E201E; /* Dark background */
  color: #ECDFCC; /* Light text color */
}

.About {
  background-color: #3C3D37; /* Medium dark background */
  color: #ECDFCC; /* Light text color */
}

.Projects {
  background-color: #697565; /* Soft green background */
  color: #ECDFCC; /* Light text color */
}

.Contact {
  background-color: #3C3D37; /* Medium dark background */
  color: #ECDFCC; /* Light text color */
}

/* Heading Styles */
h1, h2 {
  margin: 0;
  font-weight: bold;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

/* Project Items */
.project-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.project-item {
  background-color: #ECDFCC; /* Light background for project items */
  color: #1E201E; /* Dark text color */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
}

.project-item h3 {
  margin-top: 0;
  font-size: 1.5rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .App-header {
    flex-direction: column;
  }

  .header-content {
    margin-bottom: 10px; /* Space between header content and nav links */
  }

  .nav-links {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%; /* Full width for nav links */
  }

  .nav-links a {
    font-size: 1.2rem;
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .header-icon {
    width: 40px; /* Adjust size for smaller screens */
    height: 40px; /* Adjust size for smaller screens */
  }

  .header-text {
    font-size: 1.2rem; /* Adjust font size for smaller screens */
  }
}
