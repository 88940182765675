.Contact {
  background-color: #3C3D37; /* Medium dark background */
  color: #ECDFCC; /* Light text color */
  padding: 3rem 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin: 0 auto; /* Center the section */
}

.Contact h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: bold; /* Make the heading stand out */
}

.Contact p {
  font-size: 1.2rem;
  margin-bottom: 15px; /* Space between paragraphs */
}

.Contact a {
  color: #ECDFCC;
  text-decoration: none;
  font-weight: bold; /* Emphasize links */
}

.Contact a:hover {
  text-decoration: underline;
  color: #FFD700; /* Highlight color on hover */
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 20px; /* Space between icons */
  margin-top: 20px; /* Space above icons */
}

.social-icon {
  font-size: 2rem; /* Size of the icons */
  color: #ECDFCC; /* Icon color */
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #FFD700; /* Highlight color on hover */
}
.call-to-action {
  margin-top: 20px; /* Space above the call to action */
  font-size: 1.2rem;
  font-weight: bold;
}