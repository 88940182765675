.About {
  background-color: #3C3D37; /* Dark background */
  color: #ECDFCC; /* Light text color */
  padding: 3rem 20px;
  text-align: center;
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.about-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns by default */
  gap: 100px; /* Space between cards */
  max-width: 1200px;
  margin: 0 auto;
}

.card {
  perspective: 1000px; /* Enable 3D effect */
}

.card-inner {
  position: relative;
  width: 100%;
  height: 300px; /* Set a fixed height for better control */
  transition: transform 0.8s;
  transform-style: preserve-3d;
  transform-origin: center; /* Center the transformation */
}

.card:hover .card-inner {
  margin-left: 20px;
  transform: rotateY(180deg); /* Flip the card */
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 10px !important; 
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  h3 {
    font-size: xx-large;
  }

}
.card-back {
  background: url("../assets/doodles.webp") no-repeat center center;
  background-size: cover;
}
.card-back a {
  color: #ECDFCC; /* Dark color for the link */
  font-weight: bold; /* Make the link text bold */
  text-decoration: none; /* Remove underline */
  transition: color 0.3s, border-bottom 0.3s; /* Smooth transition for hover effect */
}

.card-back a:hover {
  color: #FFD700; /* Light color on hover */
  border-bottom: 2px solid #FFD700; /* Change border color on hover */
}

.card-front {
  background-color: #ECDFCC; /* Light background */
  color: #1E201E; /* Dark text color */
}

.card-back {
  background-color: #1E201E; /* Dark background for back */
  color: #ECDFCC; /* Light text color */
  transform: rotateY(180deg); /* Back side of the card */
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-grid {
    grid-template-columns: 1fr; /* 1 column on smaller screens */
  }
}
